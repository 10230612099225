import * as React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from '../layouts/layout'

const IndexPage = ({data}) => {
  return (
    <Layout pageTitle={data.wpPage.title}>
      {!!data.wpPage.content && (
        <section className="prose mx-auto">{parse(data.wpPage.content)}</section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: {eq: $id}) {
      id
      title
      slug
      content
    }
  }
`

export default IndexPage
